import React, { useState } from "react"
import { Link } from "react-router-dom"
import BannerAlert from "../../components/BannerAlert/BannerAlert"
import logo from "../../images/logo.svg"
import barsIcon from "../../images/bars-icon.svg"
import closeIcon from "../../images/close-icon.svg"
import caretDown from "../../images/caret-down.svg"
import caretUp from "../../images/caret-up.svg"
import "./NavBar.css"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"

const NavBar = ({ currentLanguage, languageOptions, changeLanguage, setCurrentLanguage, locationInfo }) => {
	const { t } = useTranslation()
	const transparentNavbarRoutes = new Set(["/", "/beta", "/beta/"])
	const [click, setClick] = useState(false)
	const [showDropdown, setShowDropdown] = useState(false)
	const [caret, setCaret] = useState(caretDown)
	const [background, setBackground] = useState(false)
	const [displayBanner, setDisplayBanner] = useState(false)

	const handleClick = () => {
		setShowDropdown(false)
		setCaret(caretDown)
		setClick(!click)
	}

	const openDropdown = () => {
		if (window.innerWidth > 1150) setShowDropdown(true)
	}

	const closeDropdown = () => {
		if (window.innerWidth > 1150) setShowDropdown(false)
	}

	const handleMobileDropdown = () => {
		if (window.innerWidth <= 1150) {
			showDropdown ? setCaret(caretDown) : setCaret(caretUp)
			setShowDropdown(!showDropdown)
		}
	}

	const closeMobileMenu = () => setClick(false)

	const changeCaret = () => {
		if (window.innerWidth > 1150) setCaret(caretDown)
	}

	const changeBackground = () => {
		if (window.scrollY >= 90) setBackground(true)
		else setBackground(false)
	}

	window.addEventListener("resize", changeCaret)
	window.addEventListener("scroll", changeBackground)

	useEffect(() => {
		if (locationInfo && locationInfo.in_eu) {
			setDisplayBanner(true)
		}
	}, [locationInfo])

	return (
		<div class="nav-area-grid">
			<BannerAlert
				displayBanner={displayBanner}
				setDisplayBanner={setDisplayBanner}
				bannerTitle={"MyWikis Europe"}
				bannerContent={"It looks like you're in the European Union. MyWikis now provides GDPR-compliant wiki hosting based in the European Union, exclusively for EU clients. Would you like to go to our European website?"}
				bannerButton={<a class="button-primary" href="https://www.mywikis.eu">Go to MyWikis.eu</a>}
			/>
			<nav
				style={
					background || !transparentNavbarRoutes.has(window.location.pathname)
						? { background: "#ffffff" }
						: { background: "transparent" }
				}
				className={
					background ? "navbar-container active" : "navbar-container"
					+ (displayBanner ? " banner-open" : "")
				}
			>
				<Link to="/" className="navbar-logo-container" onClick={closeMobileMenu}>
					<img className="navbar-logo" src={logo} alt="logo" />
					<div className="color-primary logo-text">
						<b>my</b>wikis
					</div>
				</Link>
				<div
					className={click ? "menu-icon close" : "menu-icon bars"}
					onClick={handleClick}
				>
					<img src={click ? closeIcon : barsIcon} alt="menu-icon" />
				</div>
				<ul className={"navbar-list " + (click ? "" : "closed")}>
					<li className="navbar-item">
						<Link
							to="/features"
							className="navbar-link"
							onClick={closeMobileMenu}
						>
							{t("navbar.features")}
						</Link>
					</li>
					<li className="navbar-item">
						<Link to="/plans" className="navbar-link" onClick={closeMobileMenu}>
							{t("navbar.plans")}
						</Link>
					</li>
					<li className="navbar-item">
						<a
							href="https://helpcenter.mywikis.wiki/wiki/Main_Page"
							className="navbar-link"
							onClick={closeMobileMenu}
						>
							{t("navbar.help")}
						</a>
					</li>
					<li
						className="navbar-item more"
						onMouseEnter={openDropdown}
						onMouseLeave={closeDropdown}
						onClick={handleMobileDropdown}
					>
						<div className="navbar-link">
							{t("navbar.more")}{" "}
							<img className="caret-down" src={caret} alt="caret-down" />{" "}
						</div>
						{showDropdown && (
							<ul className="dropdown-menu">
								<li className="dropdown-item">
									<Link
										to="/what-is-wiki-hosting"
										className="dropdown-link"
										onClick={() => {
											closeDropdown()
											closeMobileMenu()
										}}
									>
										{t("navbar.dropdown.what-is-wiki-hosting")}
									</Link>
								</li>
								<li className="dropdown-item">
									<Link
										to="/for-companies"
										className="dropdown-link"
										onClick={() => {
											closeDropdown()
											closeMobileMenu()
										}}
									>
										{t("navbar.dropdown.for-companies")}
									</Link>
								</li>
								<li className="dropdown-item">
									<Link
										to="/for-personal"
										className="dropdown-link"
										onClick={() => {
											closeDropdown()
											closeMobileMenu()
										}}
									>
										{t("navbar.dropdown.for-personal")}
									</Link>
								</li>
								<li className="dropdown-item">
									<Link
										to="/help-me-choose"
										className="dropdown-link"
										onClick={() => {
											closeDropdown()
											closeMobileMenu()
										}}
									>
										{t("navbar.dropdown.help-me-choose")}
									</Link>
								</li>
								<li className="dropdown-item">
									<Link
										to="/demo"
										className="dropdown-link"
										onClick={() => {
											closeDropdown()
											closeMobileMenu()
										}}
									>
										{t("navbar.dropdown.demo")}
									</Link>
								</li>
								<li className="dropdown-item">
									<Link
										to="/movehere"
										className="dropdown-link"
										onClick={() => {
											closeDropdown()
											closeMobileMenu()
										}}
									>
										{t("navbar.dropdown.move-here")}
									</Link>
								</li>
								<li className="dropdown-item">
									<Link
										to="/consulting"
										className="dropdown-link"
										onClick={() => {
											closeDropdown()
											closeMobileMenu()
										}}
									>
										{t("navbar.dropdown.consulting")}
									</Link>
								</li>
								<li className="dropdown-item">
									<Link
										to="/contact"
										className="dropdown-link"
										onClick={() => {
											closeDropdown()
											closeMobileMenu()
										}}
									>
										{t("navbar.dropdown.contact")}
									</Link>
								</li>
								<li className="dropdown-item">
									<Link
										to="/showcase"
										className="dropdown-link"
										onClick={() => {
											closeDropdown()
											closeMobileMenu()
										}}
									>
										{t("navbar.dropdown.showcase")}
									</Link>
								</li>
								<li className="dropdown-item">
									<Link
										to="/careers"
										className="dropdown-link"
										onClick={() => {
											closeDropdown()
											closeMobileMenu()
										}}
									>
										{t("navbar.dropdown.careers")}
									</Link>
								</li>
								<li className="dropdown-item">
									<Link
										to="/about"
										className="dropdown-link"
										onClick={() => {
											closeDropdown()
											closeMobileMenu()
										}}
									>
										{t("navbar.dropdown.aboutus")}
									</Link>
								</li>
								<li className="dropdown-item">
									<Link
										to="/terms"
										className="dropdown-link"
										onClick={() => {
											closeDropdown()
											closeMobileMenu()
										}}
									>
										{t("navbar.dropdown.terms")}
									</Link>
								</li>
							</ul>
						)}
					</li>
					<li className="navbar-btn-item">
						<Link
							to="/createawiki"
							className="button-secondary navbar-btn"
							onClick={closeMobileMenu}
						>
							{t("navbar.create-wiki")}
						</Link>
					</li>
					<li className="navbar-btn-item">
						<Link
							to="/login"
							className="button-primary navbar-btn"
							onClick={closeMobileMenu}
						>
							{t("navbar.login")}
						</Link>
					</li>
					<li className="navbar-language-dropdown">
						<div className="change-settings body-text-dark">
							<Select
								// menuPlacement="top"
								autoSize={true}
								name="language"
								id="language"
								// placeholder="Change Language"
								// defaultValue={{ value: "en", label: "English" }}
								value={currentLanguage}
								options={languageOptions}
								onChange={(e) => {
									changeLanguage(e)
									setCurrentLanguage(e)
								}}
							/>
						</div>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export default NavBar
